<template>
    <div>
        <SubHeader :title="'Inquiry'"/>
        <div class="p-3 text-white">
            <div class="border-bottom pb-3 mb-3 border-yellow-1">
                <p class="mb-1">{{title}}</p>
                <span class="small">{{date}}</span>
            </div>
            <div class="mb-2">Question</div>
            <div v-html="memo" :class="CheckStatus(status)" class="pb-3 mb-3" style="white-space:pre;">
                
            </div>
            <div class="mb-2" v-if="status=='Y'">Answer</div>
            <div v-html="reply" style="white-space:pre;">
                
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/common/Footer.vue'
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");
export default {
  name: 'Home',
  components: {
    Footer, SubHeader
  },
  
  data(){
    return{
      code : this.$route.params.code,
      title : '',
      memo : '',
      date : '',
      status :'',
      reply : '',


    }
  },
  mounted(){
    this.GetEventDetail();
  },
  methods:{
    CheckStatus(status){
      if(status =='Y'){
        return 'border-bottom ';
      }
    },
    GetEventDetail(){
      const code = this.code;
      const body = {code};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

      this.$http.post('/member/board/GetInquiryDetail',{req}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.title = body.info.title;
                    this.memo = body.info.memo;
                    this.date = body.info.date;
                    this.reply = body.info.reply;
                    this.status = body.info.status;
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
      )
    }
  }
}
</script>