<template>
    <div>
        <SubHeader :title="'Personal Information Setting'" :link="'/mypage'"/>
        <div class="content_wrap">

            <div class="py-3 pb-5">
                <ul>
                    <li class="flex-between-center flex-wrap mb-4">
                        <p class="text-burgundy-4">Account Email</p>
                        <p class="text-white fw-bold w-100 text-end">{{email}}</p>
                    </li>
                    <li class="flex-between-center mb-4">
                        <span class="text-burgundy-4">My Region</span>
                        <span class="text-white fw-bold">{{region}}</span>
                    </li>
                    <li class="flex-between-center mb-4">
                        <span class="text-burgundy-4">UID</span>
                        <span class="text-white fw-bold">{{uid}}</span>
                    </li>
                    <li class="flex-between-center mb-3">
                        <span class="text-burgundy-4">Date of Birth</span>
                        <span class="text-white fw-bold" v-if="birth !=''">{{birth.toLocaleDateString()}}</span>
                    </li>
                </ul>
            </div>

            <div>
                <button class="py-3 border-0 rounded-1 bg-yellow-1 btn w-100 flex-center-center fw-bold" @click="ChgPw()">
                    Change password
                </button>
            </div>
            <div class="flex-between-center mt-2">
                <button class="py-3 border-0 rounded-1 bg-yellow-4 btn w-100 flex-center-center fw-bold w-50 me-2" @click="Logout()">
                    Log out
                </button>
                <router-link to="/mypage/delete-account" class="py-3 border-0 rounded-1 bg-gray-300 w-100 flex-center-center fw-bold w-50 text-white btn">
                    Delete Account
                </router-link>
                <!-- <button class="py-3 border-0 rounded-1 bg-gray-300 w-100 flex-center-center fw-bold w-50 text-white btn" @click="delete_pp = true">
                    Delete Account
                </button> -->
            </div>
        </div>
        <Footer></Footer>


        <!-- <div class="basic_pp center_fix" v-show="delete_pp">
            <div class="dimm bg-body-i bg-opacity-50" @click="delete_pp=false"></div>
            <div class="content bg-body rounded-1">
                <div class="body overflow-auto" ref="basic_pp_body">
                    <div class="word-break-all">
                        <h4 class="text-center mb-4">Delete Account</h4>

                        <p class="mb-3">Are you sure you want to delete your OCB account? If you’re having problems, please contact <span class="text-primary" @click="$router.push('/mypage/inquiry')">Customer Service.</span></p>

                        <p class="mb-3">Please be sure to check your balance before deleting your account. </p>

                        <p class="text-decoration-underline mb-3">If you log-in with deleted account within 14 days after account delete, your account will be restored, otherwise your account will be permanently deleted.</p>

                        <p class="text-decoration-underline mb-3">Once account is permanetly deleted your asset and account can not be restored.</p>
                    </div>

                    <div class="mt-5">
                        <button class="py-3 border-0 rounded-1 bg-danger w-100 flex-center-center fw-bold fs-px-13 mb-2 text-white btn" @click="DeleteAccount()">
                            DELETE ACCOUNT
                        </button>
                        <button class="py-3 border-0 rounded-1 bg-gray-100 w-100 btn flex-center-center fw-bold fs-px-13" @click="delete_pp = false">
                            CANCEL
                        </button>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader, Footer
    },
    data(){
        return{
            email: '',
            region: '',
            uid: '',
            birth: '',
            region_list: [
                {
                    code: 'ad', 
                    title: 'andorra',
                },
                {
                    code: 'ae', 
                    title: 'united arab emirates',
                },
                {
                    code: 'af', 
                    title: 'afghanistan',
                },
                {
                    code: 'ag', 
                    title: 'antigua and barbuda',
                },
                {
                    code: 'ai', 
                    title: 'anguilla',
                },
                {
                    code: 'al', 
                    title: 'albania',
                },
                {
                    code: 'am', 
                    title: 'armenia',
                },
                {
                    code: 'an', 
                    title: 'netherlands antilles',
                },
                {
                    code: 'ao', 
                    title: 'angola',
                },
                {
                    code: 'aq', 
                    title: 'antarctica',
                },
                {
                    code: 'ar', 
                    title: 'argentina',
                },
                {
                    code: 'as', 
                    title: 'american samoa',
                },
                {
                    code: 'at', 
                    title: 'austria',
                },
                {
                    code: 'au', 
                    title: 'australia',
                },
                {
                    code: 'aw', 
                    title: 'aruba',
                },
                {
                    code: 'ax', 
                    title: 'aland islands',
                },
                {
                    code: 'az', 
                    title: 'azerbaijan',
                },
                {
                    code: 'ba', 
                    title: 'bosnia hercegovina',
                },
                {
                    code: 'bb', 
                    title: 'barbados',
                },
                {
                    code: 'bd', 
                    title: 'bangladesh',
                },
                {
                    code: 'be', 
                    title: 'belgium',
                },
                {
                    code: 'bf', 
                    title: 'burkina faso',
                },
                {
                    code: 'bg', 
                    title: 'bulgaria',
                },
                {
                    code: 'bh', 
                    title: 'bahrain',
                },
                {
                    code: 'bi', 
                    title: 'burundi',
                },
                {
                    code: 'bj', 
                    title: 'benin',
                },
                {
                    code: 'bl', 
                    title: 'saint barthelemy',
                },
                {
                    code: 'bm', 
                    title: 'bermuda',
                },
                {
                    code: 'bn', 
                    title: 'brunei darussalam',
                },
                {
                    code: 'bo', 
                    title: 'bolivia',
                },
                {
                    code: 'bq', 
                    title: 'bonaire',
                },
                {
                    code: 'br', 
                    title: 'brazil',
                },
                {
                    code: 'bs', 
                    title: 'bahamas',
                },
                {
                    code: 'bt', 
                    title: 'bhutan',
                },
                {
                    code: 'bv', 
                    title: 'bouvet island',
                },
                {
                    code: 'bw', 
                    title: 'botswana',
                },
                {
                    code: 'by', 
                    title: 'belarus',
                },
                {
                    code: 'bz', 
                    title: 'belize',
                },
                {
                    code: 'ca', 
                    title: 'canada',
                },
                {
                    code: 'cc', 
                    title: 'cocos (keeling) islands',
                },
                {
                    code: 'cd', 
                    title: 'democratic republic of the congo',
                },
                {
                    code: 'cf', 
                    title: 'central african republic',
                },
                {
                    code: 'cg', 
                    title: 'congo',
                },
                {
                    code: 'ch', 
                    title: 'switzerland',
                },
                {
                    code: 'ci', 
                    title: 'cote d`ivoire',
                },
                {
                    code: 'ck', 
                    title: 'cook islands',
                },
                {
                    code: 'cl', 
                    title: 'chile',
                },
                {
                    code: 'cm', 
                    title: 'cameroon',
                },
                {
                    code: 'cn', 
                    title: 'china',
                },
                {
                    code: 'co', 
                    title: 'colombia',
                },
                {
                    code: 'cr', 
                    title: 'costa rica',
                },
                {
                    code: 'cu', 
                    title: 'cuba',
                },
                {
                    code: 'cv', 
                    title: 'cape verde',
                },
                {
                    code: 'cw', 
                    title: 'curacao',
                },
                {
                    code: 'cx', 
                    title: 'christmas island',
                },
                {
                    code: 'cy', 
                    title: 'cyprus',
                },
                {
                    code: 'cz', 
                    title: 'czech republic',
                },
                {
                    code: 'de', 
                    title: 'germany',
                },
                {
                    code: 'dj', 
                    title: 'djibouti',
                },
                {
                    code: 'dk', 
                    title: 'denmark',
                },
                {
                    code: 'dm', 
                    title: 'dominica',
                },
                {
                    code: 'do', 
                    title: 'dominican republic',
                },
                {
                    code: 'dz', 
                    title: 'algeria',
                },
                {
                    code: 'ec', 
                    title: 'ecuador',
                },
                {
                    code: 'ee', 
                    title: 'estonia',
                },
                {
                    code: 'eg', 
                    title: 'egypt',
                },
                {
                    code: 'eh', 
                    title: 'western sahara',
                },
                {
                    code: 'er', 
                    title: 'eritrea',
                },
                {
                    code: 'es', 
                    title: 'spain',
                },
                {
                    code: 'et', 
                    title: 'ethiopia',
                },
                {
                    code: 'fi', 
                    title: 'finland',
                },
                {
                    code: 'fj', 
                    title: 'fiji',
                },
                {
                    code: 'fk', 
                    title: 'falkland islands',
                },
                {
                    code: 'fm', 
                    title: 'micronesia',
                },
                {
                    code: 'fo', 
                    title: 'faroe islands',
                },
                {
                    code: 'fr', 
                    title: 'france',
                },
                {
                    code: 'ga', 
                    title: 'gabon',
                },
                {
                    code: 'gb', 
                    title: 'united kingdom',
                },
                {
                    code: 'gd', 
                    title: 'grenada',
                },
                {
                    code: 'ge', 
                    title: 'georgia',
                },
                {
                    code: 'gf', 
                    title: 'french guiana',
                },
                {
                    code: 'gg', 
                    title: 'guernsey',
                },
                {
                    code: 'gh', 
                    title: 'ghana',
                },
                {
                    code: 'gi', 
                    title: 'gibraltar',
                },
                {
                    code: 'gl', 
                    title: 'greenland',
                },
                {
                    code: 'gm', 
                    title: 'gambia',
                },
                {
                    code: 'gn', 
                    title: 'guinea',
                },
                {
                    code: 'gp', 
                    title: 'guadeloupe',
                },
                {
                    code: 'gq', 
                    title: 'equatorial guinea',
                },
                {
                    code: 'gr', 
                    title: 'greece',
                },
                {
                    code: 'gs', 
                    title: 'south georgia and south sandwich lslands',
                },
                {
                    code: 'gt', 
                    title: 'guatemala',
                },
                {
                    code: 'gu', 
                    title: 'guam',
                },
                {
                    code: 'gw', 
                    title: 'guinea-bissau',
                },
                {
                    code: 'gy', 
                    title: 'guyana',
                },
                {
                    code: 'hk', 
                    title: 'hong kong',
                },
                {
                    code: 'hm', 
                    title: 'heard and mcdonald islands',
                },
                {
                    code: 'hn', 
                    title: 'honduras',
                },
                {
                    code: 'hr', 
                    title: 'croatia',
                },
                {
                    code: 'ht', 
                    title: 'haiti',
                },
                {
                    code: 'hu', 
                    title: 'hungary',
                },
                {
                    code: 'hw', 
                    title: 'hawaii',
                },
                {
                    code: 'id', 
                    title: 'indonesia',
                },
                {
                    code: 'ie', 
                    title: 'ireland',
                },
                {
                    code: 'il', 
                    title: 'israel',
                },
                {
                    code: 'im', 
                    title: 'isle of man',
                },
                {
                    code: 'in', 
                    title: 'india',
                },
                {
                    code: 'io', 
                    title: 'british indian ocean territory',
                },
                {
                    code: 'iq', 
                    title: 'iraq',
                },
                {
                    code: 'ir', 
                    title: 'iran',
                },
                {
                    code: 'is', 
                    title: 'iceland',
                },
                {
                    code: 'it', 
                    title: 'italy',
                },
                {
                    code: 'je', 
                    title: 'jersey',
                },
                {
                    code: 'jm', 
                    title: 'jamaica',
                },
                {
                    code: 'jo', 
                    title: 'jordan',
                },
                {
                    code: 'jp', 
                    title: 'japan',
                },
                {
                    code: 'ke', 
                    title: 'kenya',
                },
                {
                    code: 'kg', 
                    title: 'kyrgyzstan',
                },
                {
                    code: 'kh', 
                    title: 'cambodia',
                },
                {
                    code: 'ki', 
                    title: 'kiribati',
                },
                {
                    code: 'km', 
                    title: 'comoros',
                },
                {
                    code: 'kn', 
                    title: 'saint kitts and nevis',
                },
                {
                    code: 'kp', 
                    title: 'north korea',
                },
                {
                    code: 'kr', 
                    title: 'south korea',
                },
                {
                    code: 'kv', 
                    title: 'kosovo',
                },
                {
                    code: 'kw', 
                    title: 'kuwait',
                },
                {
                    code: 'ky', 
                    title: 'cayman islands',
                },
                {
                    code: 'kz', 
                    title: 'kazakhstan',
                },
                {
                    code: 'la', 
                    title: 'laos',
                },
                {
                    code: 'lb', 
                    title: 'lebanon',
                },
                {
                    code: 'lc', 
                    title: 'saint lucia',
                },
                {
                    code: 'li', 
                    title: 'liechtenstein',
                },
                {
                    code: 'lk', 
                    title: 'sri lanka',
                },
                {
                    code: 'lr', 
                    title: 'liberia',
                },
                {
                    code: 'ls', 
                    title: 'lesotho',
                },
                {
                    code: 'lt', 
                    title: 'lithuania',
                },
                {
                    code: 'lu', 
                    title: 'luxembourg',
                },
                {
                    code: 'lv', 
                    title: 'latvia',
                },
                {
                    code: 'ly', 
                    title: 'libyan arab jamahiriya',
                },
                {
                    code: 'ma', 
                    title: 'morocco',
                },
                {
                    code: 'mc', 
                    title: 'monaco',
                },
                {
                    code: 'md', 
                    title: 'moldova',
                },
                {
                    code: 'me', 
                    title: 'montenegro',
                },
                {
                    code: 'mf', 
                    title: 'saint martin (french part)',
                },
                {
                    code: 'mg', 
                    title: 'madagascar',
                },
                {
                    code: 'mh', 
                    title: 'marshall islands',
                },
                {
                    code: 'mk', 
                    title: 'macedonia',
                },
                {
                    code: 'ml', 
                    title: 'mali',
                },
                {
                    code: 'mm', 
                    title: 'myanmar',
                },
                {
                    code: 'mn', 
                    title: 'mongolia',
                },
                {
                    code: 'mo', 
                    title: 'macau',
                },
                {
                    code: 'mp', 
                    title: 'northern mariana islands',
                },
                {
                    code: 'mq', 
                    title: 'martinique',
                },
                {
                    code: 'mr', 
                    title: 'mauritania',
                },
                {
                    code: 'ms', 
                    title: 'montserrat',
                },
                {
                    code: 'mt', 
                    title: 'malta',
                },
                {
                    code: 'mu', 
                    title: 'mauritius',
                },
                {
                    code: 'mv', 
                    title: 'maldives',
                },
                {
                    code: 'mw', 
                    title: 'malawi',
                },
                {
                    code: 'mx', 
                    title: 'mexico',
                },
                {
                    code: 'my', 
                    title: 'malaysia',
                },
                {
                    code: 'mz', 
                    title: 'mozambique',
                },
                {
                    code: 'na', 
                    title: 'namibia',
                },
                {
                    code: 'nc', 
                    title: 'new caledonia',
                },
                {
                    code: 'ne', 
                    title: 'niger',
                },
                {
                    code: 'nf', 
                    title: 'norfolk island',
                },
                {
                    code: 'ng', 
                    title: 'nigeria',
                },
                {
                    code: 'ni', 
                    title: 'nicaragua',
                },
                {
                    code: 'nl', 
                    title: 'netherlands',
                },
                {
                    code: 'no', 
                    title: 'norway',
                },
                {
                    code: 'np', 
                    title: 'nepal',
                },
                {
                    code: 'nr', 
                    title: 'nauru',
                },
                {
                    code: 'nt', 
                    title: 'neutral zone',
                },
                {
                    code: 'nu', 
                    title: 'niue',
                },
                {
                    code: 'nz', 
                    title: 'new zealand',
                },
                {
                    code: 'om', 
                    title: 'oman',
                },
                {
                    code: 'pa', 
                    title: 'panama',
                },
                {
                    code: 'pe', 
                    title: 'peru',
                },
                {
                    code: 'pf', 
                    title: 'french polynesia',
                },
                {
                    code: 'pg', 
                    title: 'papua new guinea',
                },
                {
                    code: 'ph', 
                    title: 'philippines',
                },
                {
                    code: 'pk', 
                    title: 'pakistan',
                },
                {
                    code: 'pl', 
                    title: 'poland',
                },
                {
                    code: 'pm', 
                    title: 'saint pierre and miquelon',
                },
                {
                    code: 'pn', 
                    title: 'pitcairn',
                },
                {
                    code: 'pr', 
                    title: 'puerto rico',
                },
                {
                    code: 'ps', 
                    title: 'palestine',
                },
                {
                    code: 'pt', 
                    title: 'portugal',
                },
                {
                    code: 'pw', 
                    title: 'palau',
                },
                {
                    code: 'py', 
                    title: 'paraguay',
                },
                {
                    code: 'qa', 
                    title: 'qatar',
                },
                {
                    code: 're', 
                    title: 'reunion',
                },
                {
                    code: 'ro', 
                    title: 'romania',
                },
                {
                    code: 'rs', 
                    title: 'serbia',
                },
                {
                    code: 'ru', 
                    title: 'russian federation',
                },
                {
                    code: 'rw', 
                    title: 'rwanda',
                },
                {
                    code: 'sa', 
                    title: 'saudi arabia',
                },
                {
                    code: 'sb', 
                    title: 'solomon islands',
                },
                {
                    code: 'sc', 
                    title: 'seychelles',
                },
                {
                    code: 'sd', 
                    title: 'sudan',
                },
                {
                    code: 'se', 
                    title: 'sweden',
                },
                {
                    code: 'sg', 
                    title: 'singapore',
                },
                {
                    code: 'sh', 
                    title: 'saint helena',
                },
                {
                    code: 'si', 
                    title: 'slovenia',
                },
                {
                    code: 'sj', 
                    title: 'svalbard and jan mayen islands',
                },
                {
                    code: 'sk', 
                    title: 'slovakia',
                },
                {
                    code: 'sl', 
                    title: 'sierra leone',
                },
                {
                    code: 'sm', 
                    title: 'san marino',
                },
                {
                    code: 'sn', 
                    title: 'senegal',
                },
                {
                    code: 'so', 
                    title: 'somalia',
                },
                {
                    code: 'sr', 
                    title: 'suriname',
                },
                {
                    code: 'ss', 
                    title: 'south sudan',
                },
                {
                    code: 'st', 
                    title: 'sao tome and principe',
                },
                {
                    code: 'sv', 
                    title: 'el salvador',
                },
                {
                    code: 'sx', 
                    title: 'sint maarten (dutch part)',
                },
                {
                    code: 'sy', 
                    title: 'syrian arab republic',
                },
                {
                    code: 'sz', 
                    title: 'swaziland',
                },
                {
                    code: 'tc', 
                    title: 'turks and caicos islands',
                },
                {
                    code: 'td', 
                    title: 'chad',
                },
                {
                    code: 'tf', 
                    title: 'french southern territories',
                },
                {
                    code: 'tg', 
                    title: 'togo',
                },
                {
                    code: 'th', 
                    title: 'thailand',
                },
                {
                    code: 'tj', 
                    title: 'tajikistan',
                },
                {
                    code: 'tk', 
                    title: 'tokelau',
                },
                {
                    code: 'tl', 
                    title: 'east timor',
                },
                {
                    code: 'tm', 
                    title: 'turkmenistan',
                },
                {
                    code: 'tn', 
                    title: 'tunisia',
                },
                {
                    code: 'to', 
                    title: 'tonga',
                },
                {
                    code: 'tr', 
                    title: 'turkey',
                },
                {
                    code: 'tt', 
                    title: 'trinidad and tobago',
                },
                {
                    code: 'tv', 
                    title: 'tuvalu',
                },
                {
                    code: 'tw', 
                    title: 'taiwan',
                },
                {
                    code: 'tz', 
                    title: 'tanzania',
                },
                {
                    code: 'ua', 
                    title: 'ukraine',
                },
                {
                    code: 'ug', 
                    title: 'uganda',
                },
                {
                    code: 'um', 
                    title: 'united states minor outlying islands',
                },
                {
                    code: 'us', 
                    title: 'united states',
                },
                {
                    code: 'uy', 
                    title: 'uruguay',
                },
                {
                    code: 'uz', 
                    title: 'uzbekistan',
                },
                {
                    code: 'va', 
                    title: 'vatican city state',
                },
                {
                    code: 'vc', 
                    title: 'saint vincent and the grenadines',
                },
                {
                    code: 've', 
                    title: 'venezuela',
                },
                {
                    code: 'vg', 
                    title: 'virgin islands (british)',
                },
                {
                    code: 'vi', 
                    title: 'virgin islands (u.s.)',
                },
                {
                    code: 'vn', 
                    title: 'viet nam',
                },
                {
                    code: 'vu', 
                    title: 'vanuatu',
                },
                {
                    code: 'wf', 
                    title: 'wallis and futuna islands',
                },
                {
                    code: 'ws', 
                    title: 'samoa',
                },
                {
                    code: 'ye', 
                    title: 'yemen',
                },
                {
                    code: 'yt', 
                    title: 'mayotte',
                },
                {
                    code: 'yu', 
                    title: 'yugoslavia',
                },
                {
                    code: 'za', 
                    title: 'south africa',
                },
                {
                    code: 'zm', 
                    title: 'zambia',
                },
                {
                    code: 'zr', 
                    title: 'zaire',
                },
                {
                    code: 'zw', 
                    title: 'zimbabwe',
                }
            ],
            // front ui
            delete_pp: false,
        }
    },
    mounted(){
        this.GetProfileInfo();
    },
    methods:{
        GetProfileInfo(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/mypage/GetProfile',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.profile_img = body.info.profile;
                            this.uid = body.info.code;
                            this.email = body.info.email;
                            this.region = this.CheckRegion(body.info.region);
                            
                            const birth = body.info.birth;
                            this.birth = new Date(birth);
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        CheckRegion(code){
            let name =  '';
            for (const el of this.region_list) {
                if(el.code ==code){
                    name = el.title;
                }
            }

            return name;
        },
        ChgPw(){
            this.$router.push("/change-pw")
        },
        Logout(){

            this.$alert("You have been successfully logged out").then(()=>{
                this.$store.dispatch('logout').then(
                    ()=>{
                        location.href = `/signin`;
                    }
                );
            })
        },
        // OpenDelAccount(){
        //     this.delete_pp = true
        // },
        // DeleteAccount(){
        //     this.$confirm("Delete your account?").then((result)=>{
        //         if(result.is_ok){
        //             const body = {};
        //             const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

        //             this.$http.post('/member/mypage/DeleteMember',{req}).then(
        //                 (res) =>  {
        //                     if(res.status == 200){
        //                         this.loading = false;
        //                         if(res.data.code =="200"){
        //                             this.$alert("Delete account success!").then(()=>{
        //                                 this.$store.dispatch('logout').then(
        //                                     ()=>{
        //                                         location.href = `/signin`;
        //                                     }
                                            
        //                                 )
        //                             })
                                    
        //                         }else if(res.data.code =="9999"){
        //                             this.$store.dispatch('logout').then(
        //                                 ()=>{
        //                                     location.href = `/signin`;
        //                                 }
        //                             );
        //                         }
        //                     }
        //                 }   
        //             )
                    
        //         }
        //     })
        // },
    }

}
</script>
