<template>
    <div>
        <SubHeader :title="'Inquiry'" :link="'/mypage'"/>
        <div class="flex-between-center">
            <button class="py-3 border-0 btn rounded-1 bg-yellow-1 w-100 flex-center-center fw-bold" @click="GotoMoveAdd()">
                Add Inquiry
            </button>
        </div>
        <ul class="p-3 board">
          <li v-for="(item,index) in notice" :key="index" class="py-3" :class="{'border-bottom border-yellow-1' : notice&&notice.length-1!==index}">
              <router-link :to="`/mypage/inquiry/detail/${item.code}`" class="board_title flex-between-center">
                  <span class="text-white">{{item.title}}</span>
              </router-link>
              <p class="small text-white py-2">{{ item.date }}</p>
          </li>
        </ul>
        <Footer></Footer>
    </div>
</template>

<script>
// @ is an alias to /src
import BoardTop from '@/components/board/Board.vue'
import Footer from '@/components/common/Footer.vue'
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");

export default {
  name: 'Home',
  components: {
    BoardTop, Footer, SubHeader
  },
  
  data(){
    return{
      notice:[],
    }
  },
  mounted(){
    this.GetNoticeList();
  },
  methods:{
    GotoMoveAdd(){
        this.$router.push({path:"/mypage/inquiry/add"})

    },
    GetNoticeList(){
      // const status = this.status;
      const body = {};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

      this.$http.post('/member/board/GetInquiryList',{req}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.notice = body.list;
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
      )
    }
  }
}
</script>