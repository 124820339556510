<template>
    <div class="pb-0">
        <div class="bg-yellow-1 text-white">
            <router-link to="/mypage" class="float-end p-3">
                <img src="@/assets/img/layout_icon/times.svg" alt="close" width="30">
            </router-link>
            <img src="@/assets/img/mypage_referral.png" width="100%">
        </div>
        <div class="bg-green text-white px-3">
            <div class="flex-between-center border-bottom border-white py-3">
                <span>My Referral Code (=UID)</span>
                <span>{{uid}}</span>
            </div>
            <div class="py-3 border-bottom border-white">
                <span>Referral Link</span>
                <div class="flex-between-center bg-white rounded overflow-hidden mt-2">
                    <input type="text" class="form-control border-0 bg-transparent" v-model="referral_link" readonly>
                    <button class="bg-yellow-1 border-0 p-2" @click="$copy(referral_link)">
                        <img src="@/assets/img/layout_icon/copy.svg" alt="copy" width="26">
                    </button>
                </div>
            </div>
            <div class="flex-between-center py-3">
                <span>Referral</span>
                <span>{{referral_count}}</span>
            </div>
        </div>
        <div class="bg-footer text-white p-3">
            <p class="pb-3">About Referal</p>
            <ul class="fs-px-12 footer_list">
                <li>2 USDT for a newly registered user with referral code</li>
                <li>1 OCB will be rewarded for every registration with referral code</li>
                <li>0.1% of total ticket purchase amount by referred users will be rewarded to you with OCB points</li>
                <li>0.1% of uploaded Sell Deal's amount by referred users will be rewarded to you with OCB points</li>
                <li>Such rewards are only valid should a deal is successfully closed. If a deal cancelled or expired no reward will be given.</li>
                <li>OCB point is to be distributed on every 1st, 11th, 21st of each month. (schedules may change according to situations)</li>
            </ul>
            <div class="text-center py-2"><img src="@/assets/img/logo2.svg" height="20"></div>
        </div>
    </div>
</template>
<script>
const CryptoJS = require("crypto-js");
export default {
    data(){
        return{
            uid: '',
            referral_link: '',
            referral_count: ''
        }
    },
    mounted(){
        this.GetRefferInfo()
    },
    methods : {
        GetRefferInfo(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/mypage/GetRefferInfo',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            const info = body.info
                            this.uid = info.uid;
                            const link = `${process.env.VUE_APP_FRONT_URL}/signup/${info.uid}`;
                            this.referral_link = link;
                            this.referral_count = info.refferal_count;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        }
    }
}
</script>
<style lang="scss" scoped>
    .bg-green{
        background-color: #00A64C;
    }
    .bg-footer{
        background-color: #151515;
    }
    .footer_list{
        li{
            padding-bottom: 10px;
            display: flex;

            &::before{
                content: "·";
                padding-right: 10px;
            }
        }
    }
</style>